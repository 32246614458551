/**
 * This is the client-side entrypoint for your tRPC API. It is used to create the `api` object which
 * contains the Next.js App-wrapper, as well as your type-safe React Query hooks.
 *
 * We also create a few inference helpers for input and output types.
 */
import { ArticleStage, type Prisma } from "@prisma/client";
import { httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import {
  type DefaultErrorShape,
  type inferRouterInputs,
  type inferRouterOutputs,
} from "@trpc/server";
import { type TRPC_ERROR_CODE_KEY } from "@trpc/server/rpc";
import superjson from "superjson";
import { z } from "zod";

import { type AppRouter } from "~/server/api/root";

const getBaseUrl = () => {
  if (typeof window !== "undefined") return ""; // browser should use relative url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url
  return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
};

/** A set of type-safe react-query hooks for your tRPC API. */
export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      /**
       * Transformer used for data de-serialization from the server.
       *
       * @see https://trpc.io/docs/data-transformers
       */
      transformer: superjson,

      /**
       * Links used to determine request flow from client to server.
       *
       * @see https://trpc.io/docs/links
       */
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === "development" ||
            (opts.direction === "down" && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
        }),
      ],
      queryClientConfig: {
        defaultOptions: {
          queries: {
            // Whether `useQuery` should retry failed requests.
            retry: (failureCount, error) => {
              const defaultError = error as DefaultErrorShape;
              if (defaultError.data) {
                console.log("defaultError.data", defaultError.data);
                return !noRetryCodes.includes(defaultError.data?.code);
              }
              return failureCount < 3;
            },
            keepPreviousData: true,
          },
          mutations: {
            // mutate: (params) => {
            //   console.log("mutate params", params);
            //   return params.mutate(params.variables);
            // },
            retry: (failureCount, error) => {
              const defaultError = error as DefaultErrorShape;
              if (defaultError.data) {
                console.log("defaultError.data", defaultError.data);
                return !noRetryCodes.includes(defaultError.data?.code);
              }
              return failureCount < 3;
            },
          },
        },
      },
    };
  },
  /**
   * Whether tRPC should await queries when server rendering pages.
   *
   * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
   */
  ssr: false,
});

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;

// TRPC error codes that should not be retried
export const noRetryCodes: TRPC_ERROR_CODE_KEY[] = [
  "UNAUTHORIZED",
  "FORBIDDEN",
  "NOT_FOUND",
  "BAD_REQUEST",
  "PAYLOAD_TOO_LARGE",
  "METHOD_NOT_SUPPORTED",
  "CLIENT_CLOSED_REQUEST",
  "INTERNAL_SERVER_ERROR",
  "TOO_MANY_REQUESTS",
];

export const idInput = z.object({ id: z.string() });
export const idInputWithOrg = z.object({
  id: z.string(),
  orgId: z.string(),
});
export const idInputWithProject = z.object({
  id: z.string(),
  projectId: z.string(),
});

export const projectStatsInput = z.object({
  id: z.string(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  stage: z.nativeEnum(ArticleStage).optional(),
});

export const paginationReqInput = z.object({
  page: z.number(),
  pageSize: z.number(),
  sort: z.string().optional(),
  filters: z.record(z.union([z.string(), z.array(z.string())])).optional(),
  overrideFilters: z.record(z.union([z.string(), z.any()])).optional(),
});

export const idPaginationReqInput = z.object({
  id: z.string(),
  ...paginationReqInput.shape,
});

export const orgIdPaginationReqInput = z.object({
  orgId: z.string(),
  ...paginationReqInput.shape,
});

export const projectIdPaginationReqInput = z.object({
  projectId: z.string(),
  ...paginationReqInput.shape,
});

export const nextArticleReqInput = z.object({
  id: z.string(),
  page: z.number(),
  pageSize: z.number(),
  stage: z.nativeEnum(ArticleStage),
  filters: z.record(z.union([z.string(), z.array(z.string())])).optional(),
  overrideFilters: z.record(z.union([z.string(), z.any()])).optional(),
});

export function paginationInputs({
  page = 1,
  pageSize = 10,
  sort,
  filters = {},
  overrideFilters = {},
}: {
  page: number;
  pageSize: number;
  sort?: string;
  filters?: Record<string, string | string[]> | undefined;
  // overrideFilters?: object | undefined;
  overrideFilters?: Record<string, unknown> | undefined;
}) {
  console.log("pagination input filters>>>", filters);
  const skip = (page - 1) * pageSize;
  const take = pageSize;
  const [sortSplitOne, sortSplitTwo] = sort?.split(".") ?? [];
  const sortField = sortSplitOne ?? "createdAt";
  const sortDirection: Prisma.SortOrder =
    sortSplitTwo === "asc" ? "asc" : "desc";
  const filtersArray: Record<string, string | string[]>[] = [];
  Object.entries(filters).map(([key, value]) => {
    if (
      // key === "title" ||
      // key === "abstract" ||
      // key === "authors" ||
      // key === "journal" ||
      // key === "article" ||
      key === "name"
    ) {
      // parse this into a full text search query
      // Need to trim remove all spaces and replace with _ for full text search
      // Then if special operators like !, |, & are used, need trim any spaces around these
      // if (Array.isArray(value)) {
      // let parsedValue: string | string[] = [];
      //   value.forEach((v) => {
      //     parsedValue.push(
      //       v
      //       .replace(/(\s+)(!|&|\|)(\s+)/g, "$2")
      //       .split(" ")
      //       .join("_")
      //     )
      //   });
      // } else {
      // parsedValue = value
      //   .replace(/(\s+)(!|&|\|)(\s+)/g, "$2")
      //   .split(" ")
      //   .join("_");
      // }
      filtersArray.push({ [key]: value });
    }
    // else if (value.includes(".")) {
    //   const filtersValueArray = value.split(".");
    //   filtersArray.push({ [key]: filtersValueArray });
    // }
    else {
      filtersArray.push({ [key]: value });
    }
  });
  // console.log("filtersArray", filtersArray);
  return {
    skip,
    take,
    page,
    pageSize,
    sortField,
    sortDirection,
    filtersArray,
    overrideFilters,
  };
}

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literalSchema>;
type Json = Literal | { [key: string]: Json } | Json[];
export const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]),
);
